/* eslint-disable no-debugger,prefer-destructuring,class-methods-use-this */
import { action, observable, reaction, runInAction } from "mobx";
import { useAuthApi } from "../services/ApiClient";
import ApiService from "../services/ApiService";
import dataStore from "./DataStore";

class AuthStore {
  @observable isLoading = false;

  @observable error = undefined;

  @observable redirectRouteAfterAuth = "/";

  @observable redirectRouteAfterAuthwithUpload = "/fileupload";

  @observable accessToken = window.localStorage.getItem("jwt");

  @observable refreshToken = window.localStorage.getItem("rwt");

  @observable companyDetails = null;

  @observable companyBranche = null;

  @observable companyBranchen = null;

  @observable firmId = window.localStorage.getItem("firmId");

  @action setRedirectRouteAfterAuth(route) {
    this.redirectRouteAfterAuth = route;
  }

  @action setAccessToken(token) {
    window.localStorage.setItem("jwt", token);
    this.accessToken = token;
  }

  @action setRefreshToken(token) {
    window.localStorage.setItem("rwt", token);
    this.refreshToken = token;
  }

  @action setFirmId(firmId) {
    window.localStorage.setItem("firmId", firmId);
    this.firmId = firmId;
  }

  @action async fetchCompanyDetails() {
    try {
      this.companyDetails = null;
      if (this.firmId) {
        const res = await ApiService.getCompany(this.firmId);
        runInAction(() => {
          this.companyDetails = res.data;
          this.companyBranche = res.data.branche;
          this.companyBranchen = res.data.branchen;
          if (!this.companyBranche) {
            this.companyBranche = "Noshare";
          }
          if (!this.companyBranchen) {
            this.companyBranchen = "Noshare";
          }
        });
        return res;
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  constructor() {
    reaction(
      () => this.accessToken,
      (token) => {
        if (token) {
          window.localStorage.setItem("jwt", token);
        } else {
          window.localStorage.removeItem("jwt");
        }
      }
    );
    reaction(
      () => this.refreshToken,
      (token) => {
        if (token) {
          window.localStorage.setItem("rwt", token);
        } else {
          window.localStorage.removeItem("rwt");
        }
      }
    );
  }

  @action async login(username, password) {
    this.isLoading = true;
    try {
      await ApiService.login(username, password).then((res) => {
        if (res.data.success === false) {
          this.error = true;
          return;
        }
        this.setFirmId(res.data.login.firm_id[0]);
        this.setAccessToken(res.data.access_token);
        this.setRefreshToken(res.data.refresh_token);
      });
      runInAction(() => {
        this.isLoading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.error = e;
        this.isLoading = false;
      });
    }
  }

  @action async getLogin(token) {
    try {
      await ApiService.getLogin(token).then((res) => {
        if (res && res.data && res.data.firm_id && res.data.firm_id.length > 0) this.setFirmId(res.data.firm_id[0]);
      });
      await this.fetchCompanyDetails();
    } catch (e) {
      console.log(e);
    }
  }

  @action async tokenLogin(token) {
    try {
      await ApiService.tokenLogin(token).then((res) => {
        this.setFirmId(res.data.login.firm_id[0]);
        this.setAccessToken(res.data.access_token);
        this.setRefreshToken(res.data.refresh_token);
      });
      await this.fetchCompanyDetails();
    } catch (e) {
      console.log(e);
    }
  }

  @action async sendPasswordResetLink(username, urlPrefix) {
    try {
      const auth = useAuthApi();
      return await auth.sendPasswordResetEmail(username, `${urlPrefix}/login/reset/`, "company");
    } catch (e) {
      return e;
    }
  }

  @action async setNewPassword(oldPassword, newPassword) {
    const res = await ApiService.setNewPassword(oldPassword, newPassword).catch((error) => {
      throw new Error((error.response && error.response.data) || error);
    });
    return res.data.token;
  }

  @action async resetPassword(username, password, token) {
    const auth = useAuthApi();
    await auth.redeemPasswordToken({ username, password, token });
  }

  @action async logout() {
    runInAction(() => {
      this.setAccessToken(undefined);
      this.setRefreshToken(undefined);
      this.setFirmId(undefined);
      this.isAuthenticated = false;
      this.isLoading = false;
      this.companyDetails = null;
      this.companyBranche = null;
      dataStore.clear();
    });
  }
}
export default new AuthStore();
