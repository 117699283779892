/* eslint-disable no-debugger */
import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "./Modal";
import styles from "./Modal.module.scss";
import LoadingSpinnerContainer from "../LoadingSpinner/LoadingSpinnerContainer";
import theme from "../../theme";

@inject("dataStore")
@observer
class UploadInvoiceModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    data: PropTypes.any.isRequired,
    invoicedata: PropTypes.any.isRequired,
    dataStore: PropTypes.any.isRequired,
    orderId: PropTypes.any.isRequired,
    rechnungsType: PropTypes.any.isRequired,
    invoiceIsPdf: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  @observable metaData = {};

  @observable fileUploaded = false;

  @observable loading = false;

  @observable error = "";

  componentWillMount() {
    this.reset();
  }

  reset = () => {
    this.metaData = observable.object({
      group: 0,
      re_nr: "",
      dok_eingangsdat: new Date(),
      umsatz: 0,
      steuer: 0,
    });
  };

  upload = async () => {
    const { dataStore, data, orderId, invoicedata } = this.props;

    this.metaData.group = this.metaData.group || 0;

    this.error = "";

    if (invoicedata) {
      const duplicate = invoicedata.find((invoice) => invoice.re_nr === this.metaData.re_nr);
      if (duplicate) {
        this.error = "Fehler: Rechnungsnummer wurde schon verwendet";
      }
    }

    // Validate document date
    if (!moment(this.metaData.dok_eingangsdat).isValid()) {
      this.error = "Fehler: Datum muss ausgefüllt werden";
    }

    const year = moment(this.metaData.dok_eingangsdat).year();
    const actualYear = new Date().getFullYear() - 1;
    if (year < actualYear) {
      this.error = "Fehler: Aktuelles Jahr verwenden";
    }
    if (this.error === "") {
      this.metaData.umsatz = parseFloat(this.metaData.umsatz) || 0;
      this.metaData.steuer = parseFloat(this.metaData.steuer) || 0;

      this.loading = true;

      try {
        await dataStore.uploadInvoice(data.toJS()[0], this.metaData, orderId);
        this.fileUploaded = true;
        this.hide();
      } catch (e) {
        console.error(e); // Use console.error for error messages
      } finally {
        this.loading = false; // Ensure loading state is reset
      }
    }
  };

  hide = () => {
    const { onHide, onSuccess } = this.props;
    this.reset();
    if (this.fileUploaded) {
      this.fileUploaded = false;
      onSuccess();
      this.loading = false;
    } else {
      onHide();
      this.loading = false;
    }
  };

  handleChange = (e) => {
    this.metaData.group = e.target.value;
  };

  render() {
    const { show, rechnungsType, data, invoiceIsPdf } = this.props;

    const validated = false;

    const body = (
      <LoadingSpinnerContainer loading={this.loading}>
        {() => (
          <Form validated={validated}>
            <Form.Group className={styles.invoiceText}>
              <p>
                Bitte eventuelle Beilagen (zB Lieferscheine, Nachweise) mit der Rechnung gemeinsam in einer PDF-Datei
                hochladen (Rechnung an erster Stelle)
              </p>
              {show && (
                <Form.Label>
                  Rechnungs-Datei: <div className={styles.invoice}>{data[0].name}</div>
                </Form.Label>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Gruppe</Form.Label>
              <Form.Control required as='select' onChange={this.handleChange}>
                <option value={0}>Bitte Gruppe auswählen</option>
                {rechnungsType &&
                  rechnungsType.map((d) => (
                    <option key={d.dokgr_bzg} value={d.dokgr_id}>
                      {d.dokgr_bzg}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            {invoiceIsPdf && (
              <div>
                <Form.Group>
                  <Form.Label>Rechnungsnummer</Form.Label>
                  <Form.Control
                    as='input'
                    value={this.metaData.re_nr}
                    onChange={(e) => (this.metaData.re_nr = e.target.value)}
                    maxLength='35'
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Rechnungsdatum*</Form.Label>
                  <div>
                    <DatePicker
                      locale='de'
                      selected={this.metaData.dok_eingangsdat}
                      onChange={(date) => (this.metaData.dok_eingangsdat = date)}
                      dateFormat='d. MMMM yyyy'
                      className='form-control text-center'
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Umsatz (brutto)</Form.Label>
                  <Form.Control
                    as='input'
                    type='number'
                    value={this.metaData.umsatz}
                    onChange={(e) => (this.metaData.umsatz = e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Steuerbetrag</Form.Label>
                  <Form.Control
                    as='input'
                    type='number'
                    value={this.metaData.steuer}
                    onChange={(e) => (this.metaData.steuer = e.target.value)}
                  />
                </Form.Group>
              </div>
            )}
            {this.error !== "" && <p className='text-danger'>{this.error}</p>}
          </Form>
        )}
      </LoadingSpinnerContainer>
    );

    const footer = (
      <Button disabled={this.loading} variant='success' block onClick={this.upload}>
        Hochladen
      </Button>
    );

    return <Modal show={show} onHide={this.hide} title='Rechnungsdetails' body={body} footer={footer} />;
  }
}

export default UploadInvoiceModal;
