export default {
  wbg: {
    color: "#ea2210",
    title: "Die Auftragsplattform der Wohnbaugruppe Ennstal",
    modules: {
      repauftrag: true,
      planserver: true,
      filesharing: true,
    },
  },
  wbg_stage: {
    color: "#b28b16",
    title: "Die Auftragsplattform der Wohnbaugruppe Ennstal",
    modules: {
      repauftrag: true,
      planserver: true,
      filesharing: true,
    },
  },
  ebg: {
    color: "#d20040",
    title: "Die Auftragsplattform der EBG",
    modules: {
      repauftrag: false,
      planserver: false,
      filesharing: false,
    },
  },
  ebg_stage: {
    color: "#d20040",
    title: "Die Auftragsplattform der EBG (staging)",
    modules: {
      repauftrag: false,
      planserver: false,
      filesharing: false,
    },
  },
  sgk: {
    color: "#cd1719",
    title: "Die Auftragsplattform der SGK",
    modules: {
      repauftrag: false,
      planserver: false,
      filesharing: false,
    },
  },
  gwg: {
    color: "#a8c76a",
    title: "Die Auftragsplattform der GWG",
    modules: {
      repauftrag: false,
      planserver: false,
      filesharing: false,
    },
  },
  nhg: {
    color: "#accf5c",
    title: "Die Auftragsplattform der NHG",
    modules: {
      repauftrag: false,
      planserver: false,
      filesharing: false,
    },
  },
  sgl: {
    color: "#ea2210",
    title: "Die Auftragsplattform für Leoben Wohnbau",
    modules: {
      repauftrag: false,
      planserver: false,
      filesharing: false,
    },
  },
  swb: {
    color: "#00acec",
    title: "Die Auftragsplattform für Salzburg Wohnbau",
    modules: {
      repauftrag: false,
      planserver: false,
      filesharing: false,
    },
  },
  wiensued: {
    color: "#d12a32",
    title: "Die Auftragsplattform für Wien Süd",
    modules: {
      repauftrag: false,
      planserver: false,
      filesharing: false,
    },
  },
};
