import wbg from "./assets/wbg.png";
import ebg from "./assets/ebg.png";
import sgk from "./assets/sgk.png";
import gwg from "./assets/gwg.png";
import nhg from "./assets/nhg.png";
import swb from "./assets/swb.png";
import wiensued from "./assets/wiensued.png";
import sgl from "./assets/sgl.png";
import wbg_stage from "./assets/wbg_stage.png";

export const getLogoForClient = () => ({
  wbg,
  ebg,
  ebg_stage: ebg,
  sgk,
  gwg,
  nhg,
  wbg_stage,
  sgl,
  swb,
  wiensued,
});

export const currencyFormatDE = (num) =>
  `€ ${num
    .toFixed(2) // always two decimal digits
    .replace(".", ",") // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}`; // use . as a separator
