import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { observable } from "mobx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import { Table, FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditText } from "react-edit-text";
import moment from "moment";
import Modal from "./Modal";
import Toast from "../Toast/Toast";
import FileList from "../FileList/FileList";

@observer
class RepairDetailModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    onDelete: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    reparaturauftrag: PropTypes.object.isRequired,
    item: PropTypes.object,
    currentItemsCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    title: "Loading...",
    item: {},
  };

  @observable costMaterial = 0;

  @observable costMaterialText = "";

  @observable costNumber = "";

  @observable isHeatingEngineer = false;

  @observable isSponsored = false;

  @observable executionDate = new Date();

  @observable selectedEmployeeObject = null;

  @observable selectedEmployee = null;

  @observable selectedhours = null;

  @observable description = "";

  @observable repHours = [];

  @observable showToast = false;

  @observable toastStatus = {};

  @observable anhang = [];

  hide = () => {
    const { onHide } = this.props;
    onHide();
  };

  addAttachment = (e, isLieferschein) => {
    const { files } = e.target;
    const filesArr = Array.prototype.slice.call(files);
    const extension = filesArr[0].name.split(".").pop();
    let file = filesArr[0];
    if (isLieferschein) {
      file = new File([filesArr[0]], `Lieferschein.${extension}`, { type: filesArr[0].type });
    }

    this.anhang = [...this.anhang, ...[file]];
  };

  save = () => {
    const { onSuccess, currentItemsCount, item } = this.props;

    const rowItem = {
      kostennummer: parseFloat(this.costNumber, 10),
      leistung: this.description,
      ausfuehrungsdatum: moment(this.executionDate).format("YYYY-MM-DD"),
      materialaufwand: parseFloat(this.costMaterial, 10),
      materialaufwand_text: this.costMaterialText,
      heizungstechniker: this.isHeatingEngineer,
      gefoerdert: this.isSponsored,
      rep_id: "",
      id: item && item.row && item.row.id ? item.row.id : currentItemsCount + 1,
      repstunden: this.repHours,
      anhang: this.anhang,
    };

    this.isSponsored = null;
    this.costMaterial = 0;
    this.costMaterialText = "";
    this.description = "";
    this.costNumber = 0;
    this.repHours = [];
    this.selectedEmployee = null;
    this.selectedhours = null;
    this.anhang = [];

    onSuccess(rowItem);
  };

  /* deleteRow = () => {
    const { onDelete, item } = this.props;
    if (item && item.row && item.row.id) onDelete(item.row.id);
  }; */

  addRepEntry = () => {
    if (this.selectedhours && this.selectedEmployeeObject) {
      const entry = {
        mitab_id: parseInt(this.selectedEmployeeObject.id, 10),
        name: this.selectedEmployeeObject.text,
        stunden: parseFloat(this.selectedhours, 10),
        isLocal: true,
      };

      this.repHours.push(entry);
    } else {
      this.toastStatus = {
        body: "Kein Mitarbeiter ausgewählt",
        title: "Fehler!",
        type: "error",
      };
      this.showToast = true;
    }
  };

  deleteItem = (id) => {
    const filtered = this.repHours.filter((el, index) => index !== id);
    this.repHours = filtered;
  };

  changeEmployee = (e) => {
    const { reparaturauftrag } = this.props;
    this.selectedEmployeeObject = reparaturauftrag.dd_mitarbeiter[e.target.selectedIndex - 1];
    this.selectedEmployee = e.target.value;
  };

  componentDidMount() {
    const { item } = this.props;
    const { row } = item;

    if (row && row.id) {
      this.description = row.leistung;
      this.costNumber = row.kostennummer;
      this.isHeatingEngineer = row.heizungstechniker;
      this.isSponsored = row.gefoerdert;
      this.costMaterial = row.materialaufwand;
      this.costMaterialText = row.materialaufwand_text;
      this.repHours = row.repstunden;
      this.anhang = row.anhang || [];
      this.executionDate = row.ausfuehrungsdatum;
    } else {
      this.isSponsored = false;
      this.isHeatingEngineer = false;
      this.costMaterial = 0;
      this.costMaterialText = "";
      this.description = "";
      this.costNumber = 0;
      this.repHours = [];
      this.selectedEmployee = null;
      this.selectedhours = null;
      this.anhang = [];
      this.executionDate = new Date();
    }
  }

  isDateSelectable = (date) => {
    const today = new Date();
    const selectedDay = date.getDay(); // 0 (Sunday) to 6 (Saturday)
    const todayDay = today.getDay();

    // Allow only today or one working day before
    if (todayDay === 1) {
      // Monday
      return (
        date.toDateString() === today.toDateString() ||
        date.toDateString() === new Date(today.setDate(today.getDate() - 3)).toDateString()
      );
    }
    return (
      date.toDateString() === today.toDateString() ||
      date.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString()
    );
  };

  render() {
    const { show, title, reparaturauftrag, item } = this.props;
    const { row } = item;

    const body = (
      <div>
        <Container style={{ textAlign: "left" }}>
          <Row>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Ausführungsdatum</Form.Label>
                <div>
                  <DatePicker
                    weekLabel='KW'
                    showWeekNumbers
                    locale='de'
                    value={new Date(this.executionDate).toLocaleDateString()}
                    onChange={(date) => (this.executionDate = date)}
                    dateFormat='dd. MMMM yyyy'
                    filterDate={this.isDateSelectable}
                    className='form-control text-center'
                  />
                </div>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <label className='custom-file-upload'>
                <input type='file' multiple onChange={this.addAttachment} />
                <i className='fa fa-cloud-upload' />
                {` Dateien hochladen`}
              </label>
              {this.anhang && this.anhang.length > 0 && (
                <FileList
                  files={this.anhang.map((v) => ({
                    name: v.name,
                    link: process.env.PUBLIC_URL + v.linkToArchive,
                  }))}
                  iconSize='2x'
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group controlId='exampleForm.ControlSelect1'>
                <Form.Label>Kostenstelle</Form.Label>
                <Form.Control as='select' onChange={(e) => (this.costNumber = e.target.value)}>
                  <option>Bitte wählen</option>
                  {reparaturauftrag.dd_kostennummer.map((number) => (
                    <option key={number.id} selected={row && row.kostennummer === number.id} value={number.id}>
                      {number.text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col xs={12}>
              <Form.Group controlId='formBasicDate'>
                <Form.Label>Beschreibungstext</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  placeholder='Beschreibungstext'
                  value={this.description}
                  onChange={(e) => (this.description = e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group controlId='formBasicMaterial'>
                <Form.Label>Materialaufwand</Form.Label>
                <InputGroup className='mb-3'>
                  <FormControl
                    type='number'
                    min='0'
                    placeholder='Materialaufwand'
                    value={this.costMaterial}
                    onChange={(e) => (this.costMaterial = e.target.value)}
                    aria-label='Amount (to the nearest dollar)'
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>€</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col cs={6}>
              <Form.Group controlId='formBasicDate'>
                <Form.Label>Materialaufwandsbeschreibung</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={2}
                  placeholder='Materialaufwandsbeschreibung'
                  value={this.costMaterialText}
                  onChange={(e) => (this.costMaterialText = e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <label className='custom-file-upload'>
                <input type='file' multiple onChange={(e) => this.addAttachment(e, true)} />
                <i className='fa fa-cloud-upload' />
                {` Lieferscheine hochladen`}
              </label>
              {this.anhang && this.anhang.length > 0 && (
                <FileList
                  files={this.anhang.map((v) => ({
                    name: v.name,
                    link: process.env.PUBLIC_URL + v.linkToArchive,
                  }))}
                  iconSize='2x'
                />
              )}
            </Col>
            <Col xs={4} style={{ alignSelf: "center" }}>
              <Form.Check
                readOnly
                type='checkbox'
                checked={!!this.isHeatingEngineer}
                label='Heizungstechniker'
                onChange={(event) => (this.isHeatingEngineer = event.target.checked)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4} style={{ alignSelf: "center" }}>
              <Form.Check
                readOnly
                type='checkbox'
                checked={!!this.isSponsored}
                label='Gefördert'
                onChange={(event) => (this.isSponsored = event.target.checked)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Form.Group controlId='exampleForm.ControlSelect1'>
                <Form.Label>Mitarbeiter</Form.Label>
                <Form.Control as='select' value={this.selectedEmployee} onChange={(e) => this.changeEmployee(e)}>
                  <option>Bitte wählen</option>
                  {reparaturauftrag.dd_mitarbeiter.map((employee) => (
                    <option key={employee.id} name={employee.text} value={employee.id}>
                      {employee.text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group controlId='exampleForm.ControlSelect1'>
                <Form.Label>Stunden</Form.Label>
                <Form.Control
                  type='number'
                  min='0'
                  value={this.selectedhours}
                  onChange={(e) => (this.selectedhours = e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={3} style={{ alignSelf: "flex-end" }}>
              <Form.Group controlId='exampleForm.ControlSelect1'>
                <Form.Label />
                <Button onClick={this.addRepEntry} className='primary btn'>
                  +
                </Button>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Table style={{ fontSize: 14 }} striped hover variant='dark'>
                <thead>
                  <tr>
                    <th>Mitarbeiter</th>
                    <th>Stunden </th>
                    <th>Aktionen</th>
                  </tr>
                </thead>
                <tbody>
                  {this.repHours.map((entry, index) => (
                    <tr key={entry.name} id={index}>
                      <td id={index}>{entry.name}</td>
                      <td id={index}>
                        <EditText
                          // eslint-disable-next-line no-param-reassign
                          onChange={(value) => (entry.stunden = value.toString())}
                          value={entry.stunden.toString()}
                        />
                      </td>
                      {entry.isLocal && (
                        <td id={index} style={{ width: 20 }}>
                          <FontAwesomeIcon
                            onClick={() => this.deleteItem(index)}
                            color='lightgrey'
                            icon='trash-alt'
                            title='Löschen'
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    );

    const footer = (
      <>
        {/* <Button
          disabled={reparaturauftrag && reparaturauftrag.unterschrift}
          variant='danger'
          block
          onClick={this.deleteRow}
        >
          Eintrag löschen
        </Button> */}
        <Button
          disabled={reparaturauftrag && reparaturauftrag.unterschrift}
          variant='success'
          block
          onClick={this.save}
          style={{ margin: 0 }}
        >
          Eintrag speichern
        </Button>
      </>
    );

    return (
      <div>
        <Modal show={show} onSuccess={this.save} onHide={this.hide} title={title} body={body} footer={footer} />
        <Toast
          style={{ right: 40 }}
          show={this.showToast}
          onHide={() => (this.showToast = false)}
          status={this.toastStatus}
          right
        />
      </div>
    );
  }
}

export default RepairDetailModal;
