/* eslint-disable no-debugger,no-unused-vars */
import React from "react";
import { PropTypes } from "prop-types";
import { inject, observer } from "mobx-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { observable } from "mobx";
import { Button, Modal } from "react-bootstrap";
import FileDropzone from "../components/FileDropzone/FileDropzone";
import ObjectFilesTable from "../components/ObjectFilesTable";
import Header from "../components/Header/Header";
import LoadingModal from "../components/Modal/LoadingModal";
import SelectFirmModal from "../components/Modal/SelectFirmModal";
import ManageCompanyDialog from "../components/Modal/ManageCompanyDialog";
import DownloadedFileModal from "../components/Modal/DownloadFirmModal";
import Toast from "../components/Toast/Toast";
import LoadingSpinnerContainer from "../components/LoadingSpinner/LoadingSpinnerContainer";
import planStructure from "../assets/planstructure";

@inject("dataStore")
@inject("authStore")
@observer
class ObjectFilesPage extends React.Component {
  @observable uploading = false;

  @observable uploadProgress = 0;

  @observable showCompanyRightsDialog = false;

  @observable showFirmListModal = false;

  @observable showDownloadedFiles = false;

  @observable showToast = false;

  @observable companiesWithAccess = null;

  @observable toastStatus = {};

  @observable isLoadingError = false;

  @observable fileNames = [];

  @observable readFirms = null;

  @observable isUploadFiles = false;

  @observable refresh = false;

  @observable readCompanies = [];

  constructor(props) {
    super(props);
    const {
      match: {
        params: { objId },
      },
      dataStore,
    } = props;
    this.isLoadingError = false;
    if (!dataStore.objects) dataStore.loadObjects();
    dataStore
      .loadObjectFiles(objId)
      .then(() => {
        const obj = dataStore.getObject(objId);
        dataStore.getCompaniesPerObject(obj.man_id, obj.obj_nr).then(() => dataStore.loadObjectFiles(objId));
      })
      .catch(() => (this.isLoadingError = true));
  }

  sendNotificationsDialog = (files, readCompanies) => {
    this.fileNames = [files];
    this.readCompanies = readCompanies;
    this.showFirmListModal = true;
  };

  showDownloadedFilesDialog = (items, readItems) => {
    this.downloadedFirms = items;
    this.readFirms = readItems;
    this.showDownloadedFiles = true;
  };

  showCompanyRightsDialogs = (files) => {
    this.fileNames = [files];
    const {
      match: {
        params: { objId },
      },
      dataStore,
    } = this.props;
    dataStore.getCompaniesWithAccess(objId, this.fileNames[0].id).then((res) => {
      this.companiesWithAccess = res;
      this.showCompanyRightsDialog = true;
    });
  };

  hideFirmModal = (value) => {
    const {
      match: {
        params: { objId },
      },
      dataStore,
    } = this.props;

    this.fileNames = [];
    if (value.sendData) {
      this.showToast = true;
      this.toastStatus = {
        body: "Benachrichtigungen wurden gesendet!",
        title: "Erfolg",
        type: "success",
      };

      dataStore.loadObjectFiles(objId).then(() => {});
    }
    this.showFirmListModal = false;
  };

  hideCompanyRight = () => {
    this.showCompanyRightsDialog = false;
  };

  hideToast = () => {
    this.showToast = false;
  };

  checkValidation = (filename) => {
    const f = filename.split(".").slice(0, -1).join(".");
    const fileArray = f.split("_");

    const planConvention = planStructure;

    const ret = [];
    if (fileArray.length < 8) return false;

    ret.push(Object.keys(planConvention.bauteil).indexOf(fileArray[0]) > -1);

    ret.push(Object.keys(planConvention.planungsgewerk).indexOf(fileArray[1]) > -1);

    ret.push(Object.keys(planConvention.phase).indexOf(fileArray[2]) > -1);

    if (!planConvention.specialTypes.includes(fileArray[3])) {
      ret.push(Object.keys(planConvention.type).indexOf(fileArray[3]) > -1);

      ret.push(Object.keys(planConvention.info).indexOf(fileArray[5]) > -1);
    }
    ret.push(Object.keys(planConvention.geschoss).indexOf(fileArray[4]) > -1);

    ret.push(Object.keys(planConvention.vorabzug).indexOf(fileArray[7]) > -1);

    return !ret.includes(false);
  };

  checkDuplicates = (file, files) => {
    /* const filename = file.split(".").slice(0, -1).join(".");

    const freeTextfromInput = file.split("_")[9];

    if (freeTextfromInput) {
      const valid = files.map((f) => f.name === file && f.name.split("_")[9] === freeTextfromInput);
      return !!valid.includes(true);
    } */

    const valid = files.map((f) => f.name === file);
    return !!valid.includes(true);
  };

  uploadFiles = (fileToUpload) => {
    if (fileToUpload.length > 0) {
      const valid = fileToUpload.map((file) => this.checkValidation(file.name, planStructure));
      const getAllIndexes = (arr, val) => {
        const indexes = [];
        let i;
        // eslint-disable-next-line no-plusplus
        for (i = 0; i < arr.length; i++) if (arr[i] === val) indexes.push(i);
        return indexes;
      };

      const indexes = getAllIndexes(valid, false);
      if (valid.includes(false)) {
        const errorString = indexes.map(
          (index) => ` ******************* Fehler bei ${fileToUpload[index].name}  ******************* `
        );

        const error = `Namenskonvention wurde nicht eingehalten - 
        Alle Files müssen der Konvention entsprechen \n Beispiel: (R01_A_AUF_GR_G00_00_00_F) \n ${errorString.join(
          "\n"
        )}`;
        this.showToast = true;
        this.toastStatus = {
          body: error,
          title: "Upload Fehlgeschlagen!",
          type: "error",
        };
      } else {
        const {
          match: {
            params: { objId },
          },
          dataStore,
          authStore,
        } = this.props;
        let files = dataStore.objectFiles.get(objId);

        const newFiles = [];

        if (files) {
          files.filter((file) => {
            if (new Date(file.updatedAt) > new Date("April 01, 2021")) {
              newFiles.push(file);
            }
            return true;
          });
        }
        files = newFiles;

        const isDuplicate = fileToUpload.map((file) => this.checkDuplicates(file.name, files));

        if (isDuplicate.includes(true)) {
          const duplicateIndexes = getAllIndexes(isDuplicate, true);

          const errorString = duplicateIndexes.map((index) => `File ${fileToUpload[index].name} existiert bereits\n`);

          this.showToast = true;
          this.toastStatus = {
            body: errorString.join(","),
            title: "Upload Fehlgeschlagen!",
            type: "error",
          };
        } else {
          this.showFirmListModal = true;
          this.fileNames = fileToUpload;
          this.isUploadFiles = true;

          /* this.uploading = true;
          this.uploadProgress = 0;
          const obj = dataStore.getObject(objId);
          const date = new Date(Date.now()).toISOString();
          const username = authStore.companyDetails.firmen_bzg1;

          // genreateFileName
          const generatedFileNames = fileToUpload.map((file, index) => {
            const filename = file.name.split("_");
            filename[4] = `00${files.toJS().length + index + 1}`;

            return filename.join("_");
          });

          fileToUpload.map((file) => this.fileNames.push(file.name));
          Promise.all(
            fileToUpload.map((file, index) =>
              dataStore.addObjectFile(
                objId,
                generatedFileNames[index],
                file,
                username,
                date,
                obj.man_id,
                obj.obj_nr,
                (progress) => (this.uploadProgress = progress)
              )
            )
          )
            .then(() => {
              dataStore.loadObjectFiles(objId).then(() => {
                this.uploading = false;
                this.showFirmListModal = true;
              });
            })
            .catch((error) => {
              this.uploading = false;
              this.showToast = true;
              this.toastStatus = {
                body: error.message,
                title: "Upload Fehlgeschlagen!",
                type: "error",
              };
            }); */
        }
      }
    }
  };

  componentDidMount() {
    this.timer = setInterval(() => {
      this.refresh = true;
    }, 1000 * 60 * 20);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const {
      match: {
        params: { objId },
      },
      dataStore,
    } = this.props;

    const obj = dataStore.getObject(objId);
    const files = dataStore.objectFiles.get(objId);
    const companiesPerOrder = dataStore.companies;
    const newFiles = [];

    if (files) {
      files.filter((file) => {
        if (new Date(file.updatedAt) > new Date("April 01, 2021")) {
          newFiles.push(file);
        }
        return true;
      });
    }

    return (
      <div>
        <Header />
        {this.isLoadingError ? (
          <Container>
            <Row className='py-4'>
              <Col>
                <h1 className='text-center'>{`Fileupload Objekt #${objId} kann nicht geladen werden!`}</h1>
              </Col>
            </Row>
          </Container>
        ) : (
          <LoadingSpinnerContainer loading={!obj}>
            {() => (
              <Container>
                {this.refresh && (
                  <Modal show backdrop='static' keyboard={false}>
                    <Modal.Header closeButton>
                      <Modal.Title>Timeout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Timeout! Bitte aktualisieren.</Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant='primary'
                        onClick={() => {
                          this.refresh = false;
                          window.location.reload();
                        }}
                      >
                        Verstanden.
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}

                <Row className='py-3'>
                  <Col>
                    <h1 className='text-center text-break'>
                      Objekt
                      <span className='text-muted'>{` #${obj ? obj.obj_nr : objId}`}</span>
                      <br />
                      <small>{obj && obj.obj_bzg}</small>
                    </h1>
                  </Col>
                  <Col className='mt-4' lg='12'>
                    <FileDropzone onDrop={this.uploadFiles} iconSize='5x' />
                    <LoadingModal show={this.uploading} title='Uploading...' progress={this.uploadProgress} />
                  </Col>
                </Row>
                <Row>
                  {files && files.length > 0 && companiesPerOrder && companiesPerOrder.length > 0 && (
                    <Col style={{ maxWidth: "100%" }}>
                      <ObjectFilesTable
                        data={newFiles}
                        obj_nr={objId}
                        companies={companiesPerOrder}
                        openFilesDialog={this.sendNotificationsDialog}
                        openFirmsDialog={this.showDownloadedFilesDialog}
                        openRightsDialog={this.showCompanyRightsDialogs}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  {companiesPerOrder && this.showFirmListModal && (
                    <SelectFirmModal
                      show={this.showFirmListModal}
                      title='Benachrichtigungen'
                      companies={companiesPerOrder}
                      objId={objId}
                      onHide={() => this.hideFirmModal({ sendData: false })}
                      onSuccess={() => this.hideFirmModal({ sendData: true })}
                      files={this.fileNames}
                      readCompanies={this.readCompanies}
                      isUploadFiles={this.isUploadFiles}
                    />
                  )}
                </Row>
                <Row>
                  {this.showCompanyRightsDialog && (
                    <ManageCompanyDialog
                      show={this.showCompanyRightsDialog}
                      title='Rechteverwaltung'
                      companies={companiesPerOrder}
                      objId={objId}
                      onHide={() => this.hideCompanyRight({ sendData: false })}
                      onSuccess={() => this.hideCompanyRight({ sendData: true })}
                      files={this.fileNames}
                      companiesWithAccess={this.companiesWithAccess}
                    />
                  )}
                </Row>
                <Row>
                  {companiesPerOrder && (
                    <DownloadedFileModal
                      show={this.showDownloadedFiles}
                      title='Bereits gedownloaded von:'
                      onHide={() => (this.showDownloadedFiles = false)}
                      firms={this.downloadedFirms}
                      readFirms={this.readFirms}
                      companies={companiesPerOrder}
                      files={this.fileNames}
                      objId={objId}
                    />
                  )}
                </Row>
                <Toast show={this.showToast} onHide={this.hideToast} status={this.toastStatus} delay={8000} />
              </Container>
            )}
          </LoadingSpinnerContainer>
        )}
      </div>
    );
  }
}

ObjectFilesPage.propTypes = {
  dataStore: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
  authStore: PropTypes.any.isRequired,
};

export default ObjectFilesPage;
