import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker/es";
import { observable } from "mobx";
import Modal from "./Modal";

@observer
class ConfirmationModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onYes: PropTypes.func.isRequired,
    onNo: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string,
    yesText: PropTypes.string,
    noText: PropTypes.string,
    header: PropTypes.string,
    showDate: PropTypes.bool,
    beginDate: PropTypes.any,
    beginRealDate: PropTypes.any,
    repairOrder: PropTypes.any,
    pauschaleData: PropTypes.array,
    status: PropTypes.number,
  };

  static defaultProps = {
    title: "Are you sure?",
    yesText: "Yes",
    noText: "No",
    header: "Tasächlicher Beginn",
    showDate: false,
    beginDate: new Date(Date.now()),
    beginRealDate: new Date(Date.now()),
    repairOrder: null,
    pauschaleData: [],
    status: 0,
  };

  @observable beginDate = new Date(Date.now() - 60000);

  @observable selectedPauschale = null;

  render() {
    const {
      show,
      onYes,
      onNo,
      onHide,
      title,
      yesText,
      noText,
      header,
      showDate,
      beginDate,
      repairOrder,
      pauschaleData,
      status,
    } = this.props;

    const disabled =
      pauschaleData && status === 3
        ? !this.selectedPauschale || this.selectedPauschale === 0 || this.selectedPauschale === "0"
        : false;

    return (
      <Modal
        show={show}
        onHide={onHide}
        title={title}
        body={
          <Container>
            {repairOrder && pauschaleData && (
              <Row style={{ justifyContent: "center" }}>
                <Col xs={12} lg={8} md={12}>
                  <Form.Group>
                    <Form.Label>Kostenstelle</Form.Label>
                    <Form.Control as='select' onChange={(e) => (this.selectedPauschale = e.target.value)}>
                      <option value='0'>Bitte wählen</option>
                      {pauschaleData.map((entry) => (
                        <option key={entry.kosten_stellen_nummer} value={entry.kostennummer}>
                          {entry.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              {showDate && (
                <Col xs={12} lg={12} md={12}>
                  <Form.Group>
                    <Form.Label>{header}</Form.Label>
                    <div>
                      <DatePicker
                        showWeekNumbers
                        weekLabel='KW'
                        locale='de'
                        selected={this.beginDate}
                        onChange={(date) => (this.beginDate = date)}
                        {...(beginDate && (header === "Tatsächliche Fertigstellung" ? {} : {}))}
                        showTimeSelect
                        dateFormat='d. MMMM yyyy HH:mm'
                        timeFormat='HH:mm'
                        className='form-control text-center'
                      />
                    </div>
                  </Form.Group>
                </Col>
              )}
              <Col xs={6}>
                <Button
                  variant='primary'
                  disabled={disabled}
                  size='lg'
                  block
                  onClick={() => onYes(this.beginDate, this.selectedPauschale)}
                >
                  {yesText}
                </Button>
              </Col>
              <Col xs={6}>
                <Button variant='secondary' size='lg' block onClick={onNo}>
                  {noText}
                </Button>
              </Col>
            </Row>
          </Container>
        }
      />
    );
  }
}

export default ConfirmationModal;
