import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { PropTypes } from "prop-types";
import "url-search-params-polyfill";
import { observable } from "mobx";

@inject("authStore")
@inject("dataStore")
@observer
class PrivateRoute extends React.Component {
  @observable isLoading = true;

  constructor(props) {
    super();
    const {
      authStore,
      location,
      dataStore,
      match: {
        params: { orderId },
      },
    } = props;

    if (location.search !== "") {
      const query = new URLSearchParams(location.search);
      const token = query.get("token");
      const accessToken = query.get("accessToken");

      if (token) {
        authStore.setFirmId(undefined);
        authStore.setAccessToken(undefined);
        authStore.setRefreshToken(token);
        authStore.tokenLogin(token);
        this.isLoading = false;
      }
      if (accessToken) {
        if (authStore.accessToken) {
          const result = dataStore
            .loadOrderDetails(orderId)
            .catch((e) => {
              // different Company -> logout
              authStore.setAccessToken(accessToken);
              authStore.setFirmId(undefined);
              authStore.setRefreshToken(undefined);
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          authStore.setAccessToken(accessToken);

          authStore.getLogin(accessToken)

          this.isLoading = false;
        }
      }
    } else {
      this.isLoading = false;
    }
  }

  componentDidMount() {}

  render() {
    const { authStore, history, location, ...restProps } = this.props;

    if (authStore.companyBranche !== "undefined" && authStore.companyBranche === "FileSharing") {
      if (location.pathname !== "/fileupload") {
        return <Redirect to='/fileupload' />;
      }
    } else if (authStore.companyBranche !== "undefined" && authStore.companyBranche === "Noshare") {
      if (location.pathname === "/fileupload") {
        return <Redirect to='/error' />;
      }
    }
    if (!this.isLoading) {
      if (authStore.refreshToken || authStore.accessToken) {
        return <Route {...restProps} />;
      }
      authStore.setRedirectRouteAfterAuth(history.location.pathname);
      return <Redirect to='/login' />;
    }
    return <div />;
  }
}

PrivateRoute.propTypes = {
  authStore: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.any,
};

PrivateRoute.defaultProps = {
  location: null,
};

export default withRouter(PrivateRoute);
